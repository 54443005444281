import { resolveForBrowser } from '@unbounce/ensign';
import { ResolvedValuesWithDebugHandlers } from '@unbounce/ensign/build/types';
import { account_uuid, client_uuid, env, user_uuid } from './gon';

export const FEATURE_DEFINITIONS = {
  'tracking/enabled': {
    type: 'boolean',
    errorFallback: false,
    default: false,
    description: 'Send UI events, or not',
    intendedExpiry: 'never'
  },
  'tracking/endpoint': {
    type: 'string',
    errorFallback: '',
    default: '',
    description: 'Endpoint which receives UI events',
    intendedExpiry: 'never'
  },
  'tracking/mixpanelEnabled': {
    type: 'boolean',
    errorFallback: false,
    default: false,
    description: 'Send UI events to Mixpanel',
    intendedExpiry: 'never'
  },
  'uiLoading/logging': {
    type: 'boolean',
    errorFallback: true,
    default: {
      any: [{ environment: 'development' }, { environment: 'integration' }]
      // https://github.com/unbounce/ensign/blob/master/javascript/docs/advanced-feature-definitions.md
    },
    description: 'Log UI loading steps',
    intendedExpiry: 'never'
  }
} as const;

type UiHubDefinitions = typeof FEATURE_DEFINITIONS;
export type UiHubFeatures = ResolvedValuesWithDebugHandlers<UiHubDefinitions>;

const CONTEXT = {
  accounts: account_uuid,
  clients: client_uuid,
  users: user_uuid
};

const ENSIGN_ARGS = {
  appName: 'ui-hub',
  context: CONTEXT,
  environment: env,
  featureDefinitions: FEATURE_DEFINITIONS
};

const resolved = resolveForBrowser<UiHubDefinitions>(ENSIGN_ARGS);

export const getEnsignFeatures = () => resolved;
