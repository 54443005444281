export type TrackEvent = {
  eventName: string;
  properties?: Properties;
};

type Properties = {
  [key: string]: unknown;
};

export const TrackEventName = 'UnbounceTrackEvent';

export const track = (eventName: string, properties?: Properties) => {
  window.dispatchEvent(
    new CustomEvent<TrackEvent>(TrackEventName, {
      detail: {
        eventName,
        properties
      }
    })
  );
};

export type UITrackingEvent = {
  event_type: string;
  event_data?: object;
  workflow?: string;
};

export const EventNameForUIs = 'UnbounceUITrackingEvent';

/**
 * @deprecated This *only* sends data to the ui-events datalake.
 * It will eventually be removed. Use the track function instead!
 */
export const dispatchUITrackingEvent = (event: UITrackingEvent) => {
  window.dispatchEvent(
    new CustomEvent(EventNameForUIs, {
      detail: event
    })
  );
};
