import { getEnsignFeatures } from './ensign';
import { UITrackingEvent, TrackEvent, TrackEventName } from './module/tracking';
import { sendEventToDataLake } from './dataLakeSender';

let listener: EventListener;

export const addTrackingV2 = async () => {
  // Remove existing listener
  if (listener) {
    window.removeEventListener(TrackEventName, listener);
  }

  const ensignFeatures = await getEnsignFeatures();
  const mixpanelEnabled = ensignFeatures['tracking/mixpanelEnabled'];

  const eventDataLakeTrackingEnabled = ensignFeatures['tracking/enabled'];
  const endpoint = ensignFeatures['tracking/endpoint'];

  listener = ((event: CustomEvent<TrackEvent>) => {
    const { eventName, properties } = event.detail;

    // Send event to Mixpanel
    if (mixpanelEnabled) {
      window.mixpanel.track(eventName, properties);
    }

    // Send event to data lake as well
    if (eventDataLakeTrackingEnabled && endpoint) {
      const uiTrackingEvent: UITrackingEvent = {
        event_type: eventName,
        event_data: properties,
        workflow: mixpanelEnabled ? 'mixpanel' : undefined
      };
      sendEventToDataLake(uiTrackingEvent, endpoint);
    }
  }) as EventListener;

  // Listen for track events
  window.addEventListener(TrackEventName, listener);
};
