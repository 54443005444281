import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { account_uuid, client_uuid, user_uuid } from './gon';
import { UITrackingEvent } from './module/tracking';

const NAMESPACE_UUID = 'a436b0ec-cc88-4060-b601-0e1fa158ef5e'; // Constant value so we always generate the same DEFAULT_UUID for the same datestamp
const DATE = new Date();
const DATESTAMP = DATE.toISOString().substring(0, 7); // Format: 'YYYY-MM'

export const sendEventToDataLake = (event: UITrackingEvent, endpoint: string) => {
  const postBody = buildEventDataJSON(event);

  if (postBody) {
    const headers = { type: 'application/json' };
    const blob = new Blob([postBody], headers);

    navigator.sendBeacon(endpoint, blob);
  }
};

export const buildEventDataJSON = ({ event_type, event_data, workflow }: UITrackingEvent) => {
  try {
    const defaultUuid = uuidv5(DATESTAMP, NAMESPACE_UUID);
    const data = {
      event_type,
      event_uuid: uuidv4(),
      page_url: window.location.href,
      client_timestamp: Date.now(),
      account_uuid: account_uuid || defaultUuid,
      client_uuid: client_uuid || defaultUuid,
      user_uuid: user_uuid || defaultUuid,
      event_data: JSON.stringify(event_data || {}),
      workflow: workflow || null
    };

    return JSON.stringify(data);
  } catch (ex) {
    console.warn(`Bad details for ${event_type} tracking event`, event_data, ex);
    return null;
  }
};
