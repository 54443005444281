import { addTracking } from './tracking';
import { addTrackingV2 } from './tracking-v2';
import { loadUis } from './uiLoading';

void loadUis();

void addTracking();

void addTrackingV2();

if (module.hot) {
  module.hot.accept();
}
